
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'Icon',

	props: {
		name: { type: String, default: '' },
		size: { type: Number, default: 6 }
	}
})
