
import usePropModel from '#/usePropModel'

import { defineComponent, PropType } from 'vue'

import Field from './Field.vue'

export default defineComponent({
	name: 'TextField',

	components: {
		Field
	},

	props: {
		height: { type: [Number, String] as PropType<number|string>, default: 120 },
		modelValue: { type: [Number, String] as PropType<number|string>, default: '' },
	},

	setup (props, { emit }) {
		return { 
			model: usePropModel(props, emit) 
		}
	}
})
