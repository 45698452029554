
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'Button',

	props: {
		text: { type: String, default: '' }
	}
})
