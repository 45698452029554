
import { defineComponent, PropType } from 'vue'
import useNetlifyForm from '@/composables/useNetlifyForm'

import Button from '@@/gui/Button.vue'

export default defineComponent({
	name: 'NetlifyForm',

	components: {
		Button
	},

	props: {
		name: { type: String, default: '' },
		fields: { type: Array as PropType<string[]>, default: () => [] },
		subject: { type: [ String, undefined ] as PropType<string|undefined>, default: undefined },
		button: { type: String, default: 'Senda' }
	},
	
	setup (props) {
		return {
			...useNetlifyForm(props.name, props.fields, props.subject)
		}
	}
})
