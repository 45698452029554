
import { defineComponent } from 'vue'

import useNetlifyForm from '#/useNetlifyForm'

import NetlifyForm from '@@/gui/NetlifyForm.vue'
import Screen from '@@/screens/Screen.vue'

import { AreaField, TextField } from '@@/gui/fields'

export default defineComponent({
	name: 'ContactScreen',

	components: {
		AreaField,
		NetlifyForm,
		Screen,
		TextField
	}
})
