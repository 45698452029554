
import { defineComponent } from 'vue'

import Email from '@@/bits/Email.vue'

export default defineComponent({
	name: 'MainEmail',

	components: {
		Email
	}
})
