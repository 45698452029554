
import usePropModel from '#/usePropModel'

import { defineComponent, computed, PropType, watch } from 'vue'
import { is, map, update, either, find, isEmpty, o } from 'ramda'
import { isShort, defToNull, isPropTrue } from 'geri'

import Field from './Field.vue'

export default defineComponent({
	name: 'SelectField',

	components: {
		Field
	},

	props: {
		modelValue: { type: [Number, String], default: '' },
		options: { type: Array as PropType<(string|number|Option)[]>, default: () => [] },
		noAutoPick: { type: Boolean, default: false }
	},

	setup (props, { emit }) {
		const model = usePropModel(props, emit)
		const findSelected = o(defToNull, find(isPropTrue('selected')))
		const items = computed<Option[]>(() => {
			const objectify = (i: number|string): Option => ({ text: `${i}`, value: i })
			const toOption  = (i: string|number|Option): Option => (
				either(is(String), is(Number))(i) 
					? objectify(i as number|string) 
					: (i as Option)
			)
			const options = map(toOption)(props.options)
			
			return (
				isShort(options)||props.noAutoPick||findSelected(options)
					? options 
					: update(0, { ...options[0], selected: true }, options) 
			) 
		})

		watch(() => props.options, () => {
			if (isEmpty(model.value)) {
				model.value = (findSelected(items.value) as Option|null)?.value ?? ''
			}
		})

		return { model, items }
	}
})
