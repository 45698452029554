
import usePropModel from '#/usePropModel'

import { defineComponent, computed } from 'vue'
import { is } from 'ramda'

import Field from './Field.vue'

export default defineComponent({
	name: 'TextArea',

	components: {
		Field
	},

	props: {
		height: { type: [Number, String], default: 120 },
		modelValue: { type: [Number, String], default: '' },
	},

	setup (props, { emit }) {
		const model   = usePropModel(props, emit) 
		const hString = computed(() => is(Number)(props.height) ? `${props.height}px` : props.height)

		return { hString, model }
	}
})
