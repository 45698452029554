
import { defineComponent, computed } from 'vue'

import usePropModel from '#/usePropModel'

export default defineComponent({
	name: 'Field',

	props: {
		label: { type: String, default: '' },
		required: { type: Boolean, default: false },
		noPadding: { type: Boolean, default: false }
	},

	setup (props, { attrs }) {
		return {
			_slot: {
				name: attrs.name, 
				class: 'bg-transparent w-full outline-none'
			}
		}
	}
})
