
import { defineComponent } from 'vue'

import Phone from '@@/bits/Phone.vue'

export default defineComponent({
	name: 'MainPhone',

	components: {
		Phone
	}
})
