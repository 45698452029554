
import config from '@/config.json'

import { defineComponent } from 'vue'
import { replace } from 'ramda'

import Screen from '@@/screens/Screen.vue'

export default defineComponent({
	name: 'LocationScreen',

	components: {
		Screen
	},
	
	setup () {
		const address = replace(/, /g, ',<br/>')(config.address[0])

		return { address  }
	}
})
