
import { defineComponent, computed, ref, onMounted, watchEffect } from 'vue';

const count = ref<number>(0)

export default defineComponent({
	name: 'Screen',

	props: {
		id: { type: String, default: `screen-${count.value}` },
		heading: { type: String, default: '' },
		tag: { type: String, default: 'section' },
		gradient: { type: Boolean, default: false },
		noPadding: { type: Boolean, default: false },
		noBorder: { type: Boolean, default: false },
		tint: { type: [Number, String], default: 0 },
		image: { type: [Number, String], default: 0 }
	},

	setup (props) {
		const self   = ref<Data>({})
		const height = ref<string>('')

		count.value++

		function calcHeight () {
			const eh = self?.value?.offsetHeight ?? window.innerHeight
			const wh = window.innerHeight

			height.value = `${wh > eh ? wh : eh}px`
		}

		onMounted(() => {
			calcHeight()
			window.addEventListener('resize', calcHeight)
		})

		return { height, self }
	}
})
