
import { defineComponent } from 'vue'


export default defineComponent({
  name: 'FrontPage',

  components: {
  }
})
