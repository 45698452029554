
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'NavBar',

	data: () => ({
		links: [
			{ href: '#panta', text: 'Panta tíma' },
			{ href: '#stadsetning', text: 'Staðsetning' },
			{ href: '#hafa-samband', text: 'Hafa samband' }
		]
	})
})
