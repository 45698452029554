
import { defineComponent } from 'vue'

import MainEmail from '@@/bits/MainEmail.vue'
import MainPhone from '@@/bits/MainPhone.vue'

export default defineComponent({
	name: 'TheFooter',

	components: {
		MainEmail,
		MainPhone
	}
})
