
import { defineComponent } from 'vue'

import Address      from '@@/blocks/Address.vue'
import Logo         from '@@/gui/Logo.vue'
import MainPhone    from '@@/bits/MainPhone.vue'
import NavBar       from '@@/blocks/NavBar.vue'
import OpeningHours from '@@/blocks/OpeningHours.vue'
import Screen       from '@@/screens/Screen.vue'

export default defineComponent({
	name: 'TopScreen',

	components: {
		Address,
		Logo,
		MainPhone,
		NavBar,
		OpeningHours,
		Screen
	}
})
